/// <reference types="@angular/localize" />

import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from "./app/app.config";
import * as pack from "../package.json";
import * as Sentry from "@sentry/angular";

Sentry.init({
  dsn: "https://f65d71df65a048cf93dbb246d2a49ba3@o522055.ingest.us.sentry.io/5632943",
  release: pack.version,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    // Sentry.replayIntegration(),
  ],
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
  tracesSampleRate: 0.1,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Learn more at
  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.5,
  ignoreErrors: [
    "Can't find variable: gmo"
  ]
});

bootstrapApplication(AppComponent, appConfig)
  .catch(err => console.error(err));

