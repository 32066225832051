{
  "name": "vodeville-client",
  "version": "4.0.115",
  "license": "UNLICENSED",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --ssl --host localhost.vodeville.se",
    "build": "ng build --configuration production",
    "prebuild": "npm version patch",
    "postbuild": "node postbuild.js && ./node_modules/.bin/ngsw-config dist/vodeville-client/browser ./ngsw-config.json",
    "watch": "ng build --watch --configuration development",
    "compile:server": "tsc -p tsconfig.app.json",
    "prerelease": "rimraf vodeville-client.zip && yarn build && bestzip vodeville-client.zip dist/*"
  },
  "private": true,
  "dependencies": {
    "@abacritt/angularx-social-login": "2.1.0",
    "@angular/animations": "19.2.2",
    "@angular/common": "19.2.2",
    "@angular/compiler": "19.2.2",
    "@angular/core": "19.2.2",
    "@angular/forms": "19.2.2",
    "@angular/platform-browser": "19.2.2",
    "@angular/platform-browser-dynamic": "19.2.2",
    "@angular/platform-server": "19.2.2",
    "@angular/router": "19.2.2",
    "@angular/service-worker": "19.2.2",
    "@angular/ssr": "^19.2.3",
    "@auth0/angular-jwt": "5.1.2",
    "@fortawesome/angular-fontawesome": "^1.0.0",
    "@fortawesome/fontawesome-svg-core": "6.5.2",
    "@fortawesome/free-brands-svg-icons": "6.5.2",
    "@fortawesome/pro-light-svg-icons": "6.5.2",
    "@fortawesome/pro-regular-svg-icons": "6.5.2",
    "@fortawesome/pro-solid-svg-icons": "6.5.2",
    "@hkjeffchan/ngx-scroll-to": "^18.0.0",
    "@ng-bootstrap/ng-bootstrap": "18.0.0",
    "@popperjs/core": "^2.11.8",
    "@sentry/angular": "9.5.0",
    "@sentry/node": "9.5.0",
    "bootstrap": "^5.3.3",
    "chart.js": "4.4.7",
    "core-js": "^2.6.1",
    "express": "^5.0.1",
    "http-proxy-middleware": "3.0.3",
    "ng2-charts": "8.0.0",
    "ngx-toastr": "19.0.0",
    "ngx-ui-switch": "^15.0.0",
    "rxjs": "^7.8.2",
    "tslib": "^2.3.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.3",
    "@angular-eslint/builder": "19.0.2",
    "@angular-eslint/eslint-plugin": "19.0.2",
    "@angular-eslint/eslint-plugin-template": "19.0.2",
    "@angular-eslint/schematics": "19.0.2",
    "@angular-eslint/template-parser": "19.0.2",
    "@angular/cli": "19.2.3",
    "@angular/compiler-cli": "19.2.2",
    "@angular/language-service": "19.2.2",
    "@angular/localize": "19.2.2",
    "@types/express": "4.17.4",
    "@types/jasmine": "~3.6.0",
    "@types/jasminewd2": "~2.0.3",
    "@types/node": "^20.11.26",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "babel-plugin-macros": "^3.1.0",
    "bestzip": "2.1.7",
    "browser-sync": "^3.0.0",
    "eslint": "^8.57.0",
    "http-server": "^0.10.0",
    "jasmine-core": "~3.6.0",
    "jasmine-reporters": "^2.3.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "~6.3.3",
    "karma-chrome-launcher": "~3.1.0",
    "karma-cli": "~1.0.1",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.5.0",
    "purgecss": "6.0.0",
    "typescript": "5.6.3"
  },
  "resolutions": {
    "@angular-devkit/build-angular/cssnano": "5.0.4"
  }
}
