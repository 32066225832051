import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, Routes} from '@angular/router';
import {Injectable} from '@angular/core';

export const routes: Routes = [
  {path: '', loadComponent: () => import('./start/start.component').then(m => m.StartComponent)},
  {path: 'login', loadComponent: () => import('./login/login.component').then(m => m.LoginComponent)},
  {path: 'feedback', loadComponent: () => import('./feedback/feedback.component').then(m => m.FeedbackComponent)},
  {path: 'konto', loadChildren: () => import('./account/account.routing').then(m => m.ACCOUNT_ROUTES)},
  {path: 'filmtips', loadComponent: () => import('./lists/lists.component').then(m => m.ListsComponent)},
  {path: 'hitta-film', loadChildren: () => import('./search/search.routing').then(m => m.SEARCH_ROUTES)},
  {
    path: 'jamfor-tjanster',
    loadChildren: () => import('./comparison/comparison.routing').then(m => m.COMPARISON_ROUTES)
  },
  {path: 'film/:filmId', loadChildren: () => import('./film/film.routing').then(m => m.FILM_ROUTES)},
  {path: 'om-vodeville', loadChildren: () => import('./about/about.routing').then(m => m.ABOUT_ROUTES)},
  {path: 'filmfestivaler', loadChildren: () => import('./festivals/festivals.routing').then(m => m.FESTIVALS_ROUTES)},
  {path: 'sitemap', loadChildren: () => import('./sitemap/sitemap.routing').then(m => m.SITEMAP_ROUTES)},
  {path: 'din-sida', loadChildren: () => import('./profile/profile.routing').then(m => m.PROFILE_ROUTES)},
  {path: 'admin', loadChildren: () => import('./admin/admin.routing').then(m => m.ADMIN_ROUTES)},
  {
    path: 'profil',
    loadChildren: () => import('./public-profile/public-profile.routing').then(m => m.PUBLIC_PROFILE_ROUTES)
  },
  {path: 'borta', loadChildren: () => import('./not-found/not-found.routing').then(m => m.NOT_FOUND_ROUTE)},
  {path: '**', redirectTo: '/borta'}
];

// Here to avoid reloading when backing from film to search result
@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
  routesToCache: string[] = ['searchResult'];
  storedRouteHandles = new Map<string, DetachedRouteHandle>();

  // If we returned true in shouldAttach(), now return the actual route data for restoration
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.storedRouteHandles.get(route.data['key'])!;
  }

  // Return true if we have a stored route object for the next route
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return this.storedRouteHandles.has(route.data['key']);
  }

  // Decides if the route should be stored
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return this.routesToCache.indexOf(route.data['key']) > -1;
  }

  // Reuse the route if we're going to and from the same route
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  // Store the information for the route we're destructing
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.storedRouteHandles.set(route.data['key'], handle);
  }
}
